import * as React from 'react';
import { useState, useEffect} from 'react';
import { useParams, Link as LinkReact } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';



// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

const ResetPassword = ({ match }) => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [tokenValid, setTokenValid] = useState(false);

  const token = useParams();

  useEffect(() => {
 
      
      
        // Call backend endpoint to verify token validity
        axios.post(`${backEndUrl}/update/verify-token`, token )
            .then(response => {
              
                if (response.data.valid) {
                    setTokenValid(true);
                 
                } else {
                    setMessage('The password reset link has expired. Please submit a new request');
                }
            })
            .catch(error => {
                console.error('Error verifying token:', error);
                setMessage("Error: You are not authorized to reset the password. Please submit a new request.");
            });
    
}, [match]);



const handleSubmit = async (e) => {
  e.preventDefault();
  const isValidPassword = /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(password);

  if (!isValidPassword) {
      setMessage('Your password must be at least 6 characters long and include at least one uppercase letter and one number.');
      return;
  }

  try {
    if (password !== confirmPassword) {
        setMessage('The passwords do not match.');
        return;
    } else {
      const response = await axios.post(`${backEndUrl}/update/reset-password`, { token, password } );
      setMessage(response.data.message);
   
      
    }
  } catch (error) {
      console.error('Error:', error);
      setMessage('Error resetting password');
  }
};

  if (!tokenValid) {
    return (
        <div>
            <h2>Reset Password</h2>
            <p>{message}</p>
        </div>
    );
}

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Please update your password.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      {/*       <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              label="Confirmer le mot de passe"
              type="password"
              id="passwordConfirm"
              autoComplete="current-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
   {message && (
    <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
        {message}
        {message === "Password reset successfully" && (
            <div>
                <LinkReact to="/" style={{  fontSize: '1em', textDecoration: 'underline', color: 'blue' }}>
                    Log In
                </LinkReact>
            </div>
        )}
    </div>
)}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
            <Grid container>
              <Grid item xs>
             
              </Grid>
      
            </Grid>
          </Box>
        </Box>
   
      </Container>
    </ThemeProvider>
  );
}

export default ResetPassword;