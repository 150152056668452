import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useParams, useLocation, useNavigate  } from 'react-router-dom';
import "./CreateClient.scss";
import Input from "../../component/input/Input";
import Axios from "axios";
import Sidebar from "../../component/sidebar/Sidebar";
import Navbar from "../../component/navbar/Navbar";
import { Button, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { XCircleIcon } from '@heroicons/react/24/outline'; 
import { PaperClipIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';




import { ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { UsersIcon } from '@heroicons/react/24/outline'













import { XMarkIcon } from '@heroicons/react/24/outline'


import { driver } from "driver.js";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { 
  Dialog as HeadlessDialog, 
  DialogBackdrop,
  DialogPanel, 
  DialogTitle as HeadlessDialogTitle ,
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import { SparklesIcon } from '@heroicons/react/24/outline';










function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NewClient() {
  const textRef = useRef();
  const textRefi = useRef();
  const [textToCopy, setTextToCopy] = useState('');

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const { id } = useParams();
  const location = useLocation();


  const [openIndividual, setOpenIndividual] = useState(false)

  const [numberOfDocs, setNumberDoc] = useState(0);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [clientLimit, setClientLimit] = useState(false);
  const [DocLimit, setDocLimit] = useState(false);

  const [data, setData] = useState();

  const [fileName, setFileName] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('english'); 
  const [newName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("aa");
  const [newDocs, setNewDocs] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setValidationEmailError] = useState(false);
  const [newDate, setNewDate] = useState("");

  const [accepted, setAccepted] = useState(false);
  const [newDocName, setNewDocName] = useState("");
  const [program, setProgram] = useState("");
  
  const [newDocDes, setNewDocDesc] = useState("");
  const [username, setUserName] = useState("");

  const [usernameBTN, setUserNameBTN] = useState("");
  const [passwordBTN, setPasswordBTN] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [templateSelection, setTempSelec] = useState(false);
  const [template, setTemplate] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [ind, setInd] = useState(0);
  const [selectedForms, setSelectedForms] = useState([]);

  const [fileNameHandleAddField, setForHandleAddField] = useState('');
  const [fileNameUpload, setFileNameHandleAddField] = useState('');

  const [selectedTemplateId, setSelectedTemplateID] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [openAlertTemplate, setOpenAlertTemplate] = useState(false)

  const [formulaireValues, setFormulaireValues] = useState([]);

  const [openWaiting, setOpenWaiting] = useState(false);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const [emailText, setEmailText] = useState('');


  const navigate = useNavigate()

  const [query, setQuery] = useState('')
  const [openSearch, setOpenSearch] = useState(false)

  const recent = template

  const filteredPeople =
    query === ''
      ? []
      : recent.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })





  
        const handleOpenSearch = () => {
          setOpenSearch(true);
      };
      
  const handleSecondDialogOpen = () => {
    setOpenConfirmation(false); // Close the first dialog
    setOpenSecondDialog(true);  // Open the second dialog
  };
  
  const handleSecondDialogClose = () => {
    setOpenSecondDialog(false);
  };

  const copyToClipboard = () => {
        navigator.clipboard.writeText(emailText)
      .then(() => {
        alert('Text copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy text:', err);
      });
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFileNameHandleAddField(e.target.files[0].name); 
    }
    setForHandleAddField([e.target.files[0]]);
    setFileName(prevFileNames => [...prevFileNames, e.target.files[0]]);
  };





  const driverObj = driver({
    allowClose: true,
    steps: [
      { element: '.userInformation', popover: { title: 'Client Information', description: 'Fill in the information about your client.', side: "left", align: 'start' }},
      { element: '.document', popover: { title: 'Request document', description: 'In this section, you can request documents from your client. You have two options: either select from a predefined template, which you can create via the menu on the right, or add individual document.', side: "left", align: 'start'}},
     /*  { element: '#individualDoc', popover: { title: 'Request documents', description: 'You can add documents individually by clicking here.', side: "left", align: 'start'}},
      { element: '#selectTemplate', popover: { title: 'Request documents', description: 'Or select a template if you usually request the same documents. <br/><br/> Nothing prevents you from requesting documents individually as well.', side: "left", align: 'start'}},
      { element: '.ligne3', popover: { title: 'Request documents', description: 'You can also add reference documents for your client (e.g., form to fill out, bank certificate letter, etc.) here or in your template. <br/> <br/> These documents will then be visible in their client area.', side: "left", align: 'start'}},
      { element: '.ligne3bis', popover: { title: 'Set a deadline', description: 'Set a deadline by which you want to receive all your documents. <br/><br/> After this date, your client will receive a personalized email with the list of documents they have not yet submitted. You will also be copied on this email.', side: "left", align: 'start'}}, */
    ],
    onCloseClick: () => {
      if (window.confirm("Are you sure?")) {
        driverObj.destroy();
      }
    },
    onDestroyed: async () => {
      try {
        const response = await Axios({
          method: "PUT",
          withCredentials: true,
          url: `${backEndUrl}/update/showGuide/${data.id}`,
          data: {
            showGuide: false
          }
        });
      } catch (error) {
        console.error('Failed to update guide status:', error);
      }
    } 
  });


  useEffect(() => {
    if (data && data.showGuide) {
      driverObj.drive();
    }
  }, [data]);

  const [optional, setSwitchState] = useState(false);

  useEffect(() => {
    if (textRef.current) {
            setTextToCopy(textRef.current.innerText);
          }
  }, [openConfirmation]);

  useEffect(() => {
    setEmailText(`Hello ${newName} ${newLastName}, \n\n
    Here is the list of documents we will need to process your file:

    ${newDocs ? newDocs.map((doc, index) => `
        ${doc.docName} : ${doc.docDescription}
      `).join('') : ''}

    Please upload these documents to your client area at the following address: https://docup-visa.com/login \n\n
    Here are your login credentials: \n\n
    Username: ${usernameBTN} 
    Password: ${passwordBTN} \n\n
    Please upload these documents by ${newDate} \n\n
    Best regards,`);
  }, [newName, newLastName, newDocs, usernameBTN, passwordBTN, newDate]);

  const [openBackdrop, setOpenBackDop] = React.useState(false);

  const handleCloseBackDrop = () => {
    setOpenBackDop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackDop(true);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleClickOpen = (e) => {
    e.preventDefault();

    if (!usernameBTN || !passwordBTN) {
      setValidationError(true);
      return; // Exit the function
    }

    setValidationError(false);
    setOpenConfirmation(true);
  };

  const emailInput = (e) => { 
    e.preventDefault();
    let updatedEmail = '';

    if (e && e.target && e.target.value) {
      updatedEmail = e.target.value; // Update the variable if a value is present
    }

    setNewEmail(updatedEmail);

    if (updatedEmail === data.email) {
      setValidationEmailError(true);
    } else {
      setValidationEmailError(false);
    }
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  // for green pop up
  const [open, setOpen] = React.useState(false);
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = () => {
    setOpenBackDop(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // end for green pop up

  const handleAddForm = (e) => {
    e.preventDefault();

    const selectedFormName = selectedForm;
    const selectedFormInFunction = allForms.find((option) => option.name === selectedForm);

    const val = [...selectedForms];
    val.push({
      name: selectedFormInFunction.name,
      link: selectedFormInFunction.link,
      status: "uncomplete"
    });

    setSelectedForms(val);
  } 

  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: `${backEndUrl}/auth/consultantLog`,
    }).then((res) => {
      setData(res.data);       
      setTemplate(res.data.docTemplates);
      setAllForms(res.data.allForms);
   
    });
  }, []); 

  const successfulSubmit = () => {
    setOpenWaiting(true);
  }
  
  const newClient = async (e, options = { sendingRequired: true }) => {
    e.preventDefault();

    handleOpenBackDrop();
  
    if (!usernameBTN || !passwordBTN) {
      setValidationError(true);
      return; // Exit the function
    }
  
    setValidationError(false);
  
    const formData = new FormData();
    formData.append('client_firstName', newName);
    formData.append('client_lastName', newLastName);
    formData.append('program', program);
    formData.append('consultantEmail', data.email);
    formData.append('client_Username', usernameBTN);
    formData.append('client_Password', passwordBTN);
    formData.append('client_Email', newEmail);
    formData.append('subscription', data.subscription);
    formData.append('dateDeadline', newDate);
    formData.append('docNumber', numberOfDocs);
    formData.append('noDeadline', isChecked);
    formData.append('forToComplete', selectedForms);
    formData.append('numberOfClients', data.clients.length + 1);
    formData.append('templateId', selectedTemplateId);
    formData.append('sendingRequired', options.sendingRequired);
    formData.append('language', selectedLanguage);

    // Append documents if any
    newDocs.forEach((doc, index) => {
      formData.append(`documents[${index}][name]`, doc.docName);
      formData.append(`documents[${index}][description]`, doc.docDescription);
      formData.append(`documents[${index}][doc_upload]`, doc.doc_upload_consultantSide || 'none');
      formData.append(`documents[${index}][optional]`, doc.optional);
    });
  
    // Append the selected file if any
    fileName.forEach((file) => {
      if (file) {
        formData.append('doc_upload', file);
      }
    });

    try {
      const response = await Axios.put(`${backEndUrl}/update/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }).then((response) => {
        setOpen(true);
        handleCloseBackDrop();
        handleCloseConfirmation();
        setOpenSecondDialog();
        setTimeout(() => {
          window.location.reload();
        }, 3000); 
      });
    } catch (error) {
      handleCloseBackDrop();
      console.error('Failed to create client:', error);
    }
  };

  const [formValues, setFormValues] = useState([]);
  const [toggle, setToggle] = useState(false);

  const inputRef = useRef();
  const inputRefDes = useRef();
  const selectRef = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const displayUpcoming = () => { 
    alert('For now, this feature is not available but we are working on it. It will be available in the next update. Thank you for your understanding.');
  }

  const sendEmail = async () => {
    setLoading(true);
        // Define email details
    const emailData = {
      to: 'recipient@example.com', // Replace with actual recipient
      subject: 'Test Email from Gmail API',
      body: 'This is a test email sent using Gmail API and Axios.'
    };

    try {
            // Make a POST request to your backend to send the email
      const response = await Axios.post('http://localhost:9000/users/send-email', emailData, {
        withCredentials: true 
      });
      
      setMessage('Email sent successfully!');
          } catch (error) {
      console.error('Error sending email:', error);
      setMessage('Failed to send email.');
    }

    setLoading(false);
  };

  const handleChange = (e, index) => {
    const values = [...formValues];
    values[index].value = e.target.value;
    setFormValues(values);
  };    

  const handleAddField = (e) => {
    e.preventDefault();
    const values = [...formValues];
    const newFileIndex = fileName.length - 1;
    
    values.push({
      docName: newDocName,
      docDescription: newDocDes,
      doc_upload: fileName,
      doc_upload_consultantSide: fileNameHandleAddField,
      optional: optional,
    });
    

    setFormValues(values);
    setToggle(false);
    setNumberDoc(numberOfDocs + 1);
    setNewDocs(values);
    setOpenIndividual(false)
  };

  const handleAddFieldTemplate = (e) => {

  
    /* e.preventDefault(); */

    const selectedTemplateName = e
    const selectedTemplate = template.find((option) => option.name === selectedTemplateName);
    const selectedDocuments = selectedTemplate.documents;

    setSelectedTemplateID(selectedTemplate._id);

    const values = [...formValues];

    selectedDocuments.forEach((document) => {
      values.push({
        docName: document.name,
        docDescription: document.description,
        doc_upload_consultantSide: document.doc_upload_consultantSide,
        optional:document.optional
      });
    });

    setFormValues(values);
    
    setToggle(false);
    setNumberDoc(values.length);
    setNewDocs(values);
    setOpenSearch(false)
  };

  const handleDeleteField = (index) => {
    const values = [...formValues];
    values.splice(index, 1);
    setFormValues(values);
    setNewDocs(values);
    setNumberDoc(values.length);
  };

  const addBtnClick = (e) => {
    setOpenIndividual(true)
    setNewDocDesc('');
    setNewDocName('');
    setSwitchState(false);
    setForHandleAddField('');
    e.preventDefault();
    setToggle(true);
  };

  const generateUsername = async () => {
    if (newLastName === 'aa') {
      setErrorMessage(true);
      return;
    }

    if (newLastName.length < 2) {
      throw new Error('Last name must have at least two characters');
    }

    setErrorMessage(false);
    const firstTwoLetters = newLastName.slice(0, 2);
    const randomNumbers = Math.floor(Math.random() * 900) + 100;
    const username = `${firstTwoLetters}${randomNumbers}`;
    setUserNameBTN(username);
  };

  const generatePassword = async () => {
    const length = 6;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }

    setPasswordBTN(password);
  };

  if (!data) {
    return (
      <div style={{marginTop:'20%', marginLeft:'50%'}}>  
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div>

{openIndividual && (
        <div className="fixed inset-0 bg-gray-800 opacity-50 z-80 pointer-events-none" /> // Semi-transparent overlay with pointer-events-none
      )}
      <form onSubmit={handleClickOpen}>
        <div className="mt-9 ml-14 pb-5 border-b border-gray-200 pb-1">
          <h3 className=" text-xl font-semibold leading-6 text-gray-900">Create a new client</h3>
          <p className="mt-4 max-w-5xl text-sm text-gray-500">
            Fill in the information below to create a new client. You can request documents and set a deadline to receive them.
          </p>
        </div>

        <div className="tonForm">
        <div className="userInformation">
          <div className="block1">
            <div className="ligne1">
              <div className="nomPrenom">
                <label>Last Name</label>
                <TextField required size="small" type="text" placeholder="Smith" onChange={(e) => setNewLastName(e.target.value)}
                  style={{ marginLeft: '20px', boxSizing: 'border-box' }}
                /> 

                <label style={{marginLeft: '20px'}}>First Name</label>
                <TextField required size="small" type="text" placeholder="John" onChange={(e) => setNewFirstName(e.target.value)}
                  style={{ marginLeft: '15px', boxSizing: 'border-box' }}
                /> 
              </div>

              <div className="courriel">
                <label>Email</label>
                <TextField type="email" size="small" placeholder="johnsmith@gmail.com" onChange={(e) => emailInput(e)}
                  style={{ marginLeft: '20px', boxSizing: 'border-box' }}
                /> 
                {emailError ? <p style={{ color: 'red' }}>Warning : the client's email cannot be the same as yours.</p> : null}
              </div>
            </div>

            <div>
              <Backdrop
                sx={{ color: '#fff', zIndex: 1301, position: 'fixed' }}
                open={openBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>

            <div className="ligne2">
              <div className="nomFamille"></div>
            </div>

            <div className="prog"></div>
          </div>

          <div className="ligne2bis">
            <div className="nomFamille">
              <label>Username</label>
              <Button style={{'marginLeft' : '25px'}} variant='outlined' size='small' onClick={generateUsername}>Generate</Button>
              {errorMessage ? <p style={{ color: 'red' }}>Please enter the last name first</p> : null}
              {usernameBTN ? <p className="text-sm mt-4">Username: {usernameBTN}</p> : null}
            </div>

            <div className="password">
              <label>Password</label>
              <Button style={{'marginLeft' : '25px'}} variant='outlined' size='small' onClick={generatePassword}>Generate</Button>
              {passwordBTN ? <p className="text-sm mt-4">Password: {passwordBTN}</p> : null}
            </div>

            <div className="ml-4">
            <label style={{ minWidth: 'fit-content' }}>Client's language</label>
            <FormControl sx={{  marginLeft: '15px' }}>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                size="small"
                value={selectedLanguage}
                label="Language"
                onChange={handleLanguageChange}
                required
           
              >
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="french">French</MenuItem>
              </Select>
              {/* <FormHelperText>This will be the language used in all communications with the client.</FormHelperText> */}
            </FormControl>
          </div>
          </div>
          </div>

       
<div className="document">
          <div className="pb-5">
            <h3 className="mt-14 text-xl font-semibold leading-6 text-gray-900">Documents</h3>
          
          </div>

   

          
          <div className="flex items-center mt-2 mb-2">
            <h4 className="text-sm font-semibold text-gray-800 mr-4">List of Requested Documents</h4>
            <div className="flex space-x-1">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={addBtnClick}
              >
                Add Document
              </button>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-gray-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={() => {
                  if (template.length === 0) {
                    setOpenAlertTemplate(true); // Set alert to true if template is empty
                  } else {
                    handleOpenSearch(); // Call the existing function if template is not empty
                  }
                }}
              >
                Import from Template
              </button>
            </div>
          </div>

         
          <div className="center" id="individualDoc">
             
     
       
              
                
          </div> 
          <div className="divide-gray-900 mt-5 rounded-lg bg-white shadow mx-auto max-w-7xl sm:px-6 lg:px-8 ">
            <div className="max-h-96 mt-3 overflow-y-auto"> 
              {formValues.length > 0 ? (
                <ul role="list" className="mt-7 divide-y divide-gray-300">
                  {formValues.map((form, index) => (
                    <li key={form.docName} className="flex items-center justify-between gap-x-6 py-5">
                      <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                          <p className="text-m font-semibold leading-6 text-gray-900">{form.docName}</p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-m leading-5 text-gray-600">
                          <p className="relative break-words max-w-3xl leading-7">
                            {form.docDescription}
                          </p>
                          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle r={1} cx={1} cy={1} />
                          </svg>
                        </div>
                      </div>
                      <div className="flex flex-none items-center gap-x-4">
                        <XCircleIcon
                          aria-hidden="true"
                          className="mr-12 h-9 w-9 flex-none text-red-400 cursor-pointer"
                          onClick={() => handleDeleteField(index)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 mt-4">No documents added yet.</p>
              )}
            </div>
          </div>
          </div>

          <div className="realHell"></div>

          <div className="flex flex-col mt-4">
            <label className="block text-sm font-semibold text-gray-700 mb-1">Set a Deadline</label>
            <div className="flex mt-4 items-center">
              <TextField
                required
                size="small"
                type="date"
                placeholder="Enter Deadline"
                onChange={(e) => setNewDate(e.target.value)}
                className="mr-3 " // Add margin-right for spacing
              />
      
            </div>
            <div className="flex items-center mt-4">
  <SparklesIcon className="h-5 w-5 mt-5 text-yellow-500 mr-2" aria-hidden="true" /> {/* Use the StarIcon or another icon */}
  <span className="text-black mt-5 text-base">
  <strong>Magic</strong>: after this deadline, an <strong>automatic</strong> email will be sent <strong>to your client</strong>, listing the <strong>specific documents</strong> still missing from their file.
  </span>
</div>
          </div>

          <div className="ligne4">
            <React.Fragment>
            <button
                type="submit"
                className="bg-indigo-600 text-white ml-20 font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-indigo-500 transition duration-200 flex items-center"
              >
                <AccountCircleIcon className="mr-2" /> 
                Create client
              </button>
              
              <Dialog
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Create client"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {
                      clientLimit ?
                      <p style={{color:'red'}}>
                        You have reached the email limit for the free version for this month. Your client has been created, but no email has been sent. <br/><br/>
                        To benefit from more resources and be able to send more emails to your clients, we invite you to upgrade your subscription.
                      </p>
                      : null 
                    }

                    Confirm client creation? An email with their credentials and document checklist will be sent automatically.
                    <br />
                    <br />
                 {/*    <div style={{marginTop:'-40px'}} ref={textRef}>
                      <br />
                      {selectedLanguage === 'french' ? (
                        <span>
                          Bonjour {newName} {newLastName},
                          <br />
                          <br />
                          Voici la liste des documents dont nous allons avoir besoin pour traiter votre dossier :
                          <br />
                          <br />
                          {newDocs ? newDocs.map((doc, index) => (
                            <li key={index}>
                              {doc.docName} : {doc.docDescription}
                              <br />
                              <br />
                            </li>
                          )) : null}
                          <br />
                          Merci de bien vouloir téléverser ces documents sur votre espace client à l'adresse suivante : https://docup-visa.com/login
                          <br />
                          <br />
                          Voici vos identifiants de connexion :
                          <br />
                          <br />
                          Utilisateur : {usernameBTN}
                          <br />
                          Mot de passe : {passwordBTN}
                          <br />
                          <br />
                          Merci de téléverser ces documents avant le {newDate}
                          <br />
                          <br />
                          Cordialement,
                        </span>
                      ) : (
                        <span>
                          Hello {newName} {newLastName},
                          <br />
                          <br />
                          Here's the list of documents we will need to process your file:
                          <br />
                          <br />
                          {newDocs ? newDocs.map((doc, index) => (
                            <li key={index}>
                              {doc.docName} : {doc.docDescription}
                              <br />
                              <br />
                            </li>
                          )) : null}
                          <br />
                          Please upload these documents to your client area at the following address: https://docup-visa.com/login
                          <br />
                          <br />
                          Here are your login credentials:
                          <br />
                          <br />
                          Username: {usernameBTN}
                          <br />
                          Password: {passwordBTN}
                          <br />
                          <br />
                          Please upload these documents by {newDate}
                          <br />
                          <br />
                          Best regards,
                        </span>
                      )}
                    </div> */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' onClick={handleCloseConfirmation}>Cancel</Button>
                  <Button variant='contained' onClick={newClient}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Second Dialog */}
              <Dialog
                open={openSecondDialog}
                onClose={handleSecondDialogClose}
                aria-labelledby="second-dialog-title"
                aria-describedby="second-dialog-description"
                sx={{
                  '& .MuiDialog-paper': {
                    width: '800px', 
                    height: '500px', 
                    maxWidth: 'none', 
                    maxHeight: 'none' 
                  }
                }}
              >
                <DialogTitle id="second-dialog-title">
                  {" How do you want to send this message?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="second-dialog-description">
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Sending method</h3>
                        <div className="mt-5">
                          <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                            <h4 className="sr-only">Visa</h4>
                            <div className="sm:flex sm:items-start">
                              <svg viewBox="0 0 36 24" aria-hidden="true" className="h-8 w-auto sm:h-6 sm:flex-shrink-0">
                                <g id="style=doutone">
                                  <g id="email">
                                    <path id="vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M3.88534 5.2371C3.20538 5.86848 2.75 6.89295 2.75 8.5V15.5C2.75 17.107 3.20538 18.1315 3.88534 18.7629C4.57535 19.4036 5.61497 19.75 7 19.75H17C18.385 19.75 19.4246 19.4036 20.1147 18.7629C20.7946 18.1315 21.25 17.107 21.25 15.5V8.5C21.25 6.89295 20.7946 5.86848 20.1147 5.2371C19.4246 4.59637 18.385 4.25 17 4.25H7C5.61497 4.25 4.57535 4.59637 3.88534 5.2371ZM2.86466 4.1379C3.92465 3.15363 5.38503 2.75 7 2.75H17C18.615 2.75 20.0754 3.15363 21.1353 4.1379C22.2054 5.13152 22.75 6.60705 22.75 8.5V15.5C22.75 17.393 22.2054 18.8685 21.1353 19.8621C20.0754 20.8464 18.615 21.25 17 21.25H7C5.38503 21.25 3.92465 20.8464 2.86466 19.8621C1.79462 18.8685 1.25 17.393 1.25 15.5V8.5C1.25 6.60705 1.79462 5.13152 2.86466 4.1379Z" fill="#000000"/>
                                    <path id="vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M19.3633 7.31026C19.6166 7.63802 19.5562 8.10904 19.2285 8.3623L13.6814 12.6486C12.691 13.4138 11.3089 13.4138 10.3185 12.6486L4.77144 8.3623C4.44367 8.10904 4.38328 7.63802 4.63655 7.31026C4.88982 6.98249 5.36083 6.9221 5.6886 7.17537L11.2356 11.4616C11.6858 11.8095 12.3141 11.8095 12.7642 11.4616L18.3113 7.17537C18.6391 6.9221 19.1101 6.98249 19.3633 7.31026Z" fill="#BFBFBF"/>
                                  </g>
                                </g>
                              </svg>
                              <div className="mt-3 sm:ml-4 sm:mt-0">
                                <div className="text-sm font-semibold font-medium text-gray-900">Send directly by email</div>
                                <div className="mt-2 text-sm text-gray-900 sm:flex sm:items-center">
                                  <div>An email will be sent directly to the client from the address info@docup-app.com, with you in copy (CC). If the client replies, their response will be automatically forwarded to you.</div>
                                  <span aria-hidden="true" className="hidden sm:mx-2 sm:inline">&middot;</span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={(e) => newClient(e, { sendingRequired: true })}
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                            <h4 className="sr-only">Visa</h4>
                            <div className="sm:flex sm:items-start">
                              <svg viewBox="0 0 36 30" aria-hidden="true" className="h-8 w-auto sm:h-6 sm:flex-shrink-0">
                                <path d="M27.2,8.22H23.78V5.42A3.42,3.42,0,0,0,20.36,2H5.42A3.42,3.42,0,0,0,2,5.42V20.36a3.43,3.43,0,0,0,3.42,3.42h2.8V27.2A2.81,2.81,0,0,0,11,30H27.2A2.81,2.81,0,0,0,30,27.2V11A2.81,2.81,0,0,0,27.2,8.22ZM5.42,21.91a1.55,1.55,0,0,1-1.55-1.55V5.42A1.54,1.54,0,0,1,5.42,3.87H20.36a1.55,1.55,0,0,1,1.55,1.55v2.8H11A2.81,2.81,0,0,0,8.22,11V21.91ZM28.13,27.2a.93.93,0,0,1-.93.93H11a.93.93,0,0,1-.93-.93V11a.93.93,0,0,1,.93-.93H27.2a.93.93,0,0,1,.93.93Z"/>
                              </svg>
                              <div className="mt-3 sm:ml-4 sm:mt-0">
                                <div className="text-sm font-semibold font-medium text-gray-900">Copy/paste</div>
                                <div className="mt-1 text-sm text-gray-900 sm:flex sm:items-center">
                                  <div>You can copy this message and paste it directly from your own email box. If you choose this option, do not forget to press the "create" button after copying.</div>
                                  <span aria-hidden="true" className="hidden sm:mx-2 sm:inline">&middot;</span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={copyToClipboard}
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' onClick={handleSecondDialogClose}>
                    Cancel
                  </Button>
                  <Button 
                    variant='contained' 
                    onClick={() => newClient(new Event('submit'), { sendingRequired: false })}
                  >
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>

            {validationError ?
              <p style={{color:'red'}}>
                Error: please generate a 'username' and 'password'
              </p>
              : null 
            }
          </div>

          {DocLimit ?
            <p>
              Error: For the demo version, you cannot add more than 20 documents.
            </p>
            : null 
          }
          
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Client created successfully!
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <HeadlessDialog open={openIndividual} onClose={setOpenIndividual} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <HeadlessDialogTitle className="text-2xl font-semibold text-white">Add  document</HeadlessDialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpenIndividual(false)}
                        className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="size-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-base mt-2 text-indigo-300">
                      Use this form to request a specific document.
                    </p>
                  </div>
                </div>
                <div className="relative flex-1 px-4 py-6 sm:px-6">

                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white shadow rounded-lg">
                <div className="enfer">
                  
                  <TextField size="small" type="text" placeholder="Document name" className="inputDocName" onChange={(e) => setNewDocName(e.target.value)} style={{ marginTop: '30px'}} />
                  <TextField id="outlined-multiline-static" multiline rows={4} className="inputDocDes" type="text" onChange={(e) => setNewDocDesc(e.target.value)} placeholder="Document requirements" cols={5} ref={inputRefDes} 
                    style={{ marginTop: '30px'}}
                  /> 
                  <FormGroup>
                    <FormControlLabel style={{ marginTop: '15px', marginLeft: '5px'}} control={<Switch />} label="Optional" onChange={(e) => setSwitchState(e.target.checked)} />
                    <p className="mt-2 ml-2 max-w-xl text-sm text-gray-500 ">
                      Activate this option if the document is not mandatory for your client (e.g : "any relevant document, if applicable"). These won't affect file completion tracking.</p>
                  </FormGroup>

                  <div style={{ marginTop:'6%'}}>
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Add a reference model</h3>
                        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                          <div className="max-w-xl text-sm text-gray-500">
                        
                          You can upload a reference document for your client, such as a sample bank certificate, or a form to fill out. These documents will be accessible in their client portal.
                      
                            {fileNameUpload ? 
                              <div className="flex items-center ">
                                <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                <span className="truncate font-medium ml-3">{fileNameUpload}</span>
                              </div> : null }
                          </div>
                          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                            <div>
                              <label
                                htmlFor="file-upload"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 cursor-pointer"
                              >
                                Upload
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                className="hidden"
                                onChange={onChangeFile} 
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button variant="contained" onClick={handleAddField} style={{marginLeft:'33%', marginTop:'30px', marginBottom:'30px', width:'30%'}}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>


                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </HeadlessDialog>




  

{/* Search template component */}

<HeadlessDialog
      className="relative z-10"
      open={openSearch}
      onClose={() => {
        setOpenSearch(false)
        setQuery('')
      }}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-900/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel
          transition
          className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <Combobox
            onChange={(person) => {
           
            }}
          >
            {({ activeOption }) => (
              <>
                <div className="grid grid-cols-1">
                  <ComboboxInput
                    autoFocus
                    className="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                    onClick={(event) => event.preventDefault()}
                  />
                  <MagnifyingGlassIcon
                    className="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400"
                    aria-hidden="true"
                  />
                </div>

                {(query === '' || filteredPeople.length > 0) && (
                  <ComboboxOptions onClick={(event) => event.preventDefault()} as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                    <div
                      className={classNames(
                        'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                        activeOption && 'sm:h-96',
                      )}
                    >
                      {query === '' && (
                        <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">Your templates</h2>
                      )}
                      <div className="-mx-2 text-sm text-gray-700">
                      
                        {(query === '' ? recent : filteredPeople).map((template) => (
                          <ComboboxOption
                            as="div"
                            key={template.id}
                            value={template}
                            className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                            onClick={(event) => event.preventDefault()}
                          >
                        {/*     <img src={person.imageUrl} alt="" className="size-6 flex-none rounded-full" /> */}
                            <span className="ml-3 flex-auto truncate">{template.name}</span>
                            
                            <ChevronRightIcon
                              className="ml-3 hidden size-5 flex-none text-gray-400 group-data-[focus]:block"
                              aria-hidden="true"
                            />
                          </ComboboxOption>
                        ))}
                      </div>
                    </div>

                    {activeOption && (
                      <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                        <div className="flex-none p-6 text-center">
                 
                          <h2 className="mt-3 font-semibold text-gray-900">{activeOption.name}</h2>

                          <button
                            type="button"
                            className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                 onClick={(e) => {
         
          handleAddFieldTemplate(activeOption.name); // Call the function with the template name
        }}
                          >
                            Add to checklist
                          </button>
                    
                          <ul className="divide-y divide-gray-200 mt-4 ">
                          {activeOption.documents.map((document, index) => (
                                    <li key={index} className="py-2 flex items-center justify-between">
                                        <span className="text-sm font-medium text-gray-800">{document.name}</span>
               
                                    </li>
                                  ))}
                                    </ul>
                        </div>
            
                          <div className="flex flex-auto flex-col justify-between p-6">
                  
                        </div> 
                      </div>
                    )}
                  </ComboboxOptions>
                )}

                {query !== '' && filteredPeople.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <UsersIcon className="mx-auto size-6 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 font-semibold text-gray-900">No template found</p>
                    <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </HeadlessDialog>



{/* Search template component */}


{/* Modale create template first please  */}

<HeadlessDialog open={openAlertTemplate} onClose={setOpenAlertTemplate} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-red-100">
                <WarningIcon aria-hidden="true" className="size-6 text-red-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  You don't have any template yet 
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You can create your own template right now by clicking this button !
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

            <button
      type="button"
      onClick={() => {
   
        navigate('/homepage/docTemplate/docTemplate'); 
      }}
      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
    >
      Create
    </button>

              <button
                type="button"
                data-autofocus
                onClick={() => setOpenAlertTemplate(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </HeadlessDialog>




{/* Modale create template first please  */}




      </form>
    </div>
  );
}

