import React from "react";
import "./FakeIndi.scss"
import Datatable from "../../component/datatable/Datatable";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useState, useEffect } from "react";
import UserDoc from "../../component/usersDoc/UsersDoc";
import { useParams } from "react-router-dom";
import TableDoc from "../../component/TableDoc/TableDoc";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import DocReview from "../../component/docReview/docReview";
import DocForEmail from "../../component/docForEmail/docForEmail";
import WrongDocs from "../../component/wrongDocs/wrongDocs";
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; 
import Chip from '@mui/material/Chip';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormToComplete from '../../component/formToComplete/formToComplete'
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//shit about tabs 

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


export default function Fake () {

 
  const [isEditing, setIsEditing] = useState(false);

  const [openDate, setOpenDate] = useState(false);

  const handleClickOpenDate = () => {
    setOpenDate(true);
};

const handleCloseDate = () => {
    setOpenDate(false);
};

  const handleModifyClick = () => {
    setIsEditing(true);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSaveClick = async () => {

    
    try {
      await Axios ({
          method: "PUT",
          withCredentials: true,
          data: {
            note: note, 
            client_id: data.clients[clientIndice]._id, 
          },
          
           url : `${backEndUrl}/updateClient/note/` 
        
      })
    } catch (error) {
      
    }

    setIsEditing(false);
  };

  const [inputText, setInputText] = useState('');

  const handleChangei = (event) => {
    setInputText(event.target.value);
  };

    const [data, setData] = useState();
  
    const [note, setNote] = useState('');
    const { id } = useParams()
    const [buttonText, setNewText] = useState("Edit name");
    const [toggle, setToggle] = useState(false);
    const [clientNewName, setNewName] = useState("");

    const [docNewName, setNewDocName] = useState("");
    const [docNewDes, setNewDocDes] = useState("");

    const [docName, setDocName] = useState("");
    const [docDescription, setDocDescription] = useState("");
    
    const [deadline, setDeadline] = useState("");

    const [toggleNewDoc, setToggleNewDoc] = useState(false);
    const [buttonAddDoc, setNewTextAddDoc] = useState("Demander un autre document");
    const [newDeadline, setNewDeadline] = useState("");
    const [displayDeadline, setToggleNewdeadline] = useState(false);
    const [notUploadedDoc, setNotUploadedDocuments] = useState("");
    const [emailSent, setEmailSent] = useState(false);
   // const [value, setValue] = useState("");

   const [allFormsCompleted, setAllFormsCompleted] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);
   const [warningDate, setWarningDate] = useState(false);
   

   
// Stuff for confirmation window
   const [openNewDoc, setOpenNewDoc] = React.useState(false);

/// Stuff for confirmation window

const [askConfirmationNewDoc, setAskConfirmationNewDoc] = React.useState(false);

   const handleConfirmationNewDoc = () => {
    setAskConfirmationNewDoc(true);
  };
   const handleCloseConfirmationNewDoc = () => {
    setAskConfirmationNewDoc(false);
  };

  ///

  const handleClickOpenNewDoc = () => {
    const currentDate = dayjs.utc();

    setDeadline(dayjs.utc(data.clients[clientIndice].dateDeadline));


    if (currentDate.isAfter(data.clients[clientIndice].dateDeadline)) {
      setWarningDate(true);
      } else {
        setWarningDate(false);
      }

    setOpenNewDoc(true);
  };

  const handleCloseNewDoc = () => {
    setOpenNewDoc(false);
  };


  const handleCloseConfirmation = () => {
    setEmailSent(false); // Reset emailSent state to false when the confirmation window is closed
  }




  // End Stuff for confirmation window

   const handleDateChange = (date) => {
    setDeadline(date); // Save the selected date in the state variable
  };

var li = 0;

const [openEmailConfirmation, setOpenEmailConfirmation] = React.useState(false);

const handleClickOpenEmailConfirmation = () => {
  setOpenEmailConfirmation(true);
};

const handleCloseEmailConfirmation = () => {
  setOpenEmailConfirmation(false);
  setEmailSent(false);
};




// shit about tab display 

const [value, setValue] = React.useState(0);
const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleStatusChange = async () => {
  try {
      
    await Axios ({
        method: "PUT",
        withCredentials: true,
        data: {
          client_id: data.clients[clientIndice]._id, 
        },
        
         url : `${backEndUrl}/updateClient/statusChange/` 
      
    })
    window.location.reload();
  } catch (error) {
    
  }
}

const sendForm = () => {
  setOpenNewDoc(false);

  Axios.post(`${backEndUrl}/updateClient/addDoc`, {
   doc_name: docName,
    doc_description: docDescription,
   client_id: data.clients[clientIndice]._id,
  deadline: deadline
  },
  {
    withCredentials: true, 
  }
  )
  .then(response => {
    
   window.location.reload();
  })
  .catch(error => {
    console.error("Error:", error);
    // Handle the error here
  });
}

const askNewDocAndSendEmail = () => {

  const newDoc = {
    "name": docName,
    "description": docDescription,
    "doc_upload": "",
    "doc_upload_consultantSide": "",
    "attachment_ind": false,
    "status": "not uploaded",
    "review": "",
    "date": null,
    "size": 0,
    "newDoc": true,
    "_id": "new_unique_id" // Ensure to generate a unique ID if necessary
  };

  notUploadedDocs.push(newDoc);

  setOpenNewDoc(false);

  Axios.post(`${backEndUrl}/updateClient/askNewDocAndSendEmail`, {
    doc_name: notUploadedDocs,
    client_id: data.clients[clientIndice]._id,
    deadline: new Date(deadline).toLocaleDateString('en-GB'),
    client_name: data.clients[clientIndice].client_firstName + " " + data.clients[clientIndice].client_lastName, 
    client_email: data.clients[clientIndice].client_Email,
    consultant_email: data.email,
  },
  {
    withCredentials: true, 
  })
  .then(response => {
    // Check if the response status is 200 (OK)
   
   
    if (response.status === 200) {
      window.location.reload(); // Reload the page only on success
    }
    
  })
  .catch(error => {
    console.error("Error:", error);
    // Handle the error here
  });
}


const changeDeadline = async () => {
  try {

    const today = new Date();
    const selectedDateObject = new Date(deadline);


    if (selectedDateObject < today) {
      // Display an alert if selectedDate is smaller
      alert("Please select a date after today.");
      return; // Exit the function without making the Axios request
    }


    await Axios ({
        method: "PUT",
        withCredentials: true,
        data: {
          newDate: selectedDateObject, 
          client_id: data.clients[clientIndice]._id, 
          status: data.clients[clientIndice].fileStatus
        },
        
         url : `${backEndUrl}/updateClient/deadlineChange/`
      
    }).then(() => {
    window.location.reload();
    })
  } catch (error) {
    
  }
}






    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
          url: `${backEndUrl}/auth`,
     
         
          }).then((res) => {
           setData(res.data);
     
          
           
          });
     }, []) 

     const showUpdate = (e) => {
        e.preventDefault();
        setToggle(!toggle);
        if (toggle) setNewText("Edit name");
        else  
        setNewText("Hide edit");
      };

      


var notUploadedDocs = [];
  

      const sendEmailMissingDoc = async () => {
       //  
        try {
      
         const response = await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                  client_name: data.clients[clientIndice].client_firstName + " " + data.clients[clientIndice].client_lastName, 
                  client_email: data.clients[clientIndice].client_Email,
                  consultant_email: data.email,
                  missing_doc : notUploadedDocs,
                  deadline: new Date(data.clients[clientIndice].dateDeadline).toLocaleDateString('en-GB'),
                  language: data.clients[clientIndice].language,
              },
              
               url : `${backEndUrl}/updateClient/sendEmailMissing` ,
            
          },

          )
          if (response.status === 200) {
            setEmailSent(true);
            setTimeout(() => {
              setEmailSent(false);
              window.location.reload();
              
            }, 3000);
         

          }

        } catch (error) {
          
        }
      }


      // function to update the client name
      const updateClient = async () => {
        try {
      
          await Axios ({
              method: "PUT",
              withCredentials: true,
              data: {
                  client_name: clientNewName, 
              },
              
               url : `${backEndUrl}/updateClient/name/` + data.clients[clientIndice]._id ,
            
          })
      
        } catch (error) {
          
        }
      }

      // to do : replace the strings here by the value. In this case, you need to use useState to store the value of the input. Also need to toogle the update input if cliked.

      const addDocument = async () => {

        

        if (data.clients[clientIndice].documents.length > 20)
          {
            alert("Vous ne pouvez pas ajouter plus de 20 documents")
            return;
          }


        try {

       
      
          await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                 client_id: data.clients[clientIndice]._id,
                  doc_name : docNewName,
                  doc_description: docNewDes,
              },
              
               url : `${backEndUrl}/updateClient/addDoc` ,
            
          }).then(() => {
         
            window.location.reload();
          })
      
        } catch (error) {
          
        }
      }


      // create a function to update the fileStatus of the client

      const updateFileStatus = async () => {
        try {
          await Axios ({
            method: "PUT",
            withCredentials: true,
            data: {
               client_id: data.clients[clientIndice]._id,
               //newDeadline: newDeadline,
               
            },
            
             url : `${backEndUrl}/updateClient/ChangeStatusIfOnlyForms` ,
          
        })

         window.location.reload();
    
      } catch (error) {
        
      }
    }










      const newDeadLine = async () => {
    
        try {

          await Axios ({
              method: "PUT",
              withCredentials: true,
              data: {
                 client_id: data.clients[clientIndice]._id,
                 //newDeadline: newDeadline,
                 newDeadline: value,
              },
              
               url : `${backEndUrl}/updateClient/newDeadline` ,
            
          })

          // window.location.reload();
      
        } catch (error) {
          
        }
      }
      

      const sendEmail = async () => {
      

        try {
      


          await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                clientEmail : data.clients[clientIndice].client_Email,
                clientName : data.clients[clientIndice].client_name,
                consultantEmail : data.email,

              },

                url : `${backEndUrl}/users/sendEmail` ,

          })

          // window.location.reload();

        } catch (error) {
          
        }
      }


  

  function displayDocumentsMissing(doc) {
    return <DocForEmail
        name={doc.name}
        description={doc.description}
        status={doc.status}
        review={doc.review}
        reason={doc.reason}
    />
} 

   
useEffect(() => {
  if (data) {
    // Find the client that matches the id
    const client = data.clients.find(client => client._id === id);
    if (client) {
      setNote(client.note);
    }
  }
}, [data, id]);
 


    if (data === undefined) {

        return (

          <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
        )
    }
    
    if (!data){
        return 
        <h1>Not allowed</h1>
        
    } 

    if (data){

      
      var clientIndice;

        // when we have data, we need to find the client that matches the id
     for (let i = 0; i < data.clients.length; i++) {
        if (data.clients[i]._id === id) {
            // when we find the client, we need to store the indice of the client in the array
     
            // which then become the clientIndice i that we can use to render HTML
            var clientIndice = i;
           
        
            break
        }
    }





        var notUploadedDocs = data.clients[clientIndice].documents.filter(doc => doc.status === 'not uploaded');
       

   
        const translateStatus = (status) => {
          switch (status) {
            case "uncomplete":
              return "incomplet";
            case "late":
              return "Retard";
            case "complete":
              return "Complet";
            // Add more cases if needed
            default:
              return status; // Return the original status if no translation is found
          }
        };

    let statusClassName = "status"

    if (data.clients[clientIndice].fileStatus === "uncomplete") {
      statusClassName = "warning";
    } else if (data.clients[clientIndice].fileStatus === "complete") {
      statusClassName = "success";
    } else if (data.clients[clientIndice].fileStatus === "client to notify") {
      statusClassName = "toNotify";
    }
    else if (data.clients[clientIndice].fileStatus=== "to review") {
      statusClassName = "toRev";
    }
    else if (data.clients[clientIndice].fileStatus=== "late") {
      statusClassName = "error";
    }

    return (
        <div>


         

            <div className="homeee">
      

  
        
        <div className="clientContainer">

       
      <div className="nameStatus">
      
             <h1> {data.clients[clientIndice].client_firstName}  {data.clients[clientIndice].client_lastName}</h1>

            
             <p className={statusClassName}>

             <Chip
                style={{ marginLeft: '7%' }}
                size="small"
                label={data.clients[clientIndice].fileStatus === 'uncomplete' ? 'incomplete' : data.clients[clientIndice].fileStatus}
                color={statusClassName}
            />

</p>
      </div>
             
             <div>

<Box sx={{ width: '100%' , marginTop:'1%'}}>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
  <Tab label="Documents" {...a11yProps(0)} />

  <Tab label="Information" {...a11yProps(1)} />
  <Tab label="Note" {...a11yProps(2)} />
</Tabs>
</Box>
<CustomTabPanel value={value} index={0}>



        



          <div className="addDoc">

          <div className="flex justify-end space-x-4 mt-2 mr-6">
    <button
        variant="contained"
        onClick={handleClickOpenNewDoc}
       className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
    >
       Request New Document
    </button>

    {data.emailCount >= 100 && data.subscription === 'free' ? (
        <button
            variant="contained"
            disabled
            className="bg-gray-400 text-white cursor-not-allowed opacity-50"
        >
            Send email
        </button>
    ) : (
        <button
            variant="contained"
            onClick={handleClickOpenEmailConfirmation}
           className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Notify client 
        </button>
    )}
</div>

         {/*  windows for send e-mail confirmation */}

         <React.Fragment>
     
      <Dialog
        open={openEmailConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send this email ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       
          
            Here is the email that will be sent to your client. You can also just copy/paste this: 

        <br/>
        <br/>
        Hello {data.clients[clientIndice].client_firstName} {data.clients[clientIndice].client_lastName},
        <br/>
      
        <br/>
        Please upload the following documents to your DocUp account available through the following link: https://docup-visa.com/login
        <br/>
        <br/>
        {data.clients[clientIndice].documents.map(displayDocumentsMissing)}
        <br/>
        <br/>
        Please upload the missing documents before <strong> {new Date(data.clients[clientIndice].dateDeadline).toLocaleDateString('en-GB')}</strong>.
        <br/>
        <br/>
        Thank you, 
        <br/>
        <br/>
        {emailSent ? <p style={{color: 'green', fontSize: '20px' }}>Your email has been successfully sent</p> : null}
     

          </DialogContentText>
        </DialogContent>
        <DialogActions onClose={handleCloseConfirmation}>
          <Button variant='outlined' size='small' onClick={handleCloseEmailConfirmation}>Cancel</Button>
          <Button variant="contained" size="small" onClick={sendEmailMissingDoc} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>



         {/*  windows for send e-mail confirmation */}

          <React.Fragment>
          <Dialog
        open={openNewDoc}
        onClose={handleCloseNewDoc}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>Request new document </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Enter the name and requirements for the new document.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            onChange={e => setDocName(e.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="description"
            name="description"
            label="Requirements"
            type="text"
            multiline
            onChange={e => setDocDescription(e.target.value)}
            fullWidth
            variant="standard"
            style={{marginTop:'5%'}}
          />
           <LocalizationProvider  dateAdapter={AdapterDayjs}>
           <div  style={{ marginTop:'5%'}}>
           <label style={{ marginRight:'5%'}} >Deadline : </label>
      <DatePicker

      value={deadline}
      format="YYYY/MM/DD" 
      onChange={handleDateChange}
       />
       </div>
       {warningDate ? <p style={{color: 'red', fontSize: '15px', marginTop:'4%' }}>WARNING: The deadline has passed. Please select a date after today.</p> : null}
    </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' size='small' onClick={handleCloseNewDoc}>Cancel</Button>
 {/*          <Button onClick={sendForm}>Valider</Button> */}
          <Button variant='contained' size='small' onClick={askNewDocAndSendEmail}>Confirm</Button> 

        </DialogActions>
      </Dialog>
    </React.Fragment>


{/* 
    <React.Fragment>
          <Dialog
        open={askConfirmationNewDoc}
        onClose={handleCloseConfirmationNewDoc}
        maxWidth='md'
 
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}

      >
        <DialogTitle>Notify client ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
       
          Would you like to notify the client by email now or continue your verification?
       
          </DialogContentText>
        
           <LocalizationProvider  dateAdapter={AdapterDayjs}>
  
    
    </LocalizationProvider>
        </DialogContent>
        <DialogActions>
  
          <Button  variant="outlined" size='small' style={{marginRight:'45%'}} onClick={handleCloseConfirmationNewDoc}>Cancel</Button>
          <Button variant='outlined' size='small' onClick={sendForm}>Later</Button>
          <Button variant='contained' size='small' onClick={askNewDocAndSendEmail}>Send email</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> */}


          {toggleNewDoc ? (
            <div className="addDocForm">
                <form onSubmit={addDocument}>
            
                <label>Nom du document</label>
                <input className="addDocFormInput" type="text" onChange={(e) => setNewDocName(e.target.value)} />
               
               
                <label className="LabelInput">Description du document</label>
                <input  className="addDocFormInput" type="text" onChange={(e) => setNewDocDes(e.target.value)} />
               
               
                <Button  variant="contained"  size="small" className="LabelInput" type="submit"> Valider</Button>
             
                </form>
                </div>
                ) : null}

            {toggle ? (
                <form onSubmit={updateClient}>
                <div>
                <label>New client's name</label>
                <input type="text" onChange={(e) => setNewName(e.target.value)} />
                <button type="submit"> Update</button>
                </div>
                </form>
                ) : null}

          </div>

         
          {emailSent ? <p>Email sent</p> : null}

<TableDoc 
              clients={data.clients[clientIndice].documents}
              deadline={data.clients[clientIndice].dateDeadline}
             
              rid={data.id}
              getRowId={(row) => row.data.clients._id}
              ridi={data.clients[clientIndice]._id}
              storageUsed={data.storageUsed}
           
        /> 




          <div className="email2">

  


</div> 


</CustomTabPanel>

<CustomTabPanel value={value} index={1}>

<div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none pr-12">
            <div>
              <h2 className="text-base/7 font-semibold text-gray-900">Client Details</h2>
              <p className="mt-1 text-sm/6 text-gray-500">
              Here you can view and update the client's personal information.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">First name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].client_firstName}</div>
              
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Last name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].client_lastName}</div>
                 
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Username</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].client_Username}</div>
                   
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].client_Password}</div>
                
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].client_Email}</div>
                  
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Communication</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.clients[clientIndice].language}</div>
                  
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Deadline</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{(data.clients[clientIndice].dateDeadline).substring(0, 10)}</div>
                    <button  onClick={handleClickOpenDate} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button>
                  </dd>
                </div>
              </dl>
            </div>
            </div>


</CustomTabPanel>
<CustomTabPanel value={value} index={2}>

<div className="geal">

<div className="relative mt-8">
<div aria-hidden="true" className="absolute inset-0 flex items-center">
<div className="w-full border-t border-gray-300" />
</div>
<div className="relative flex justify-center">
<span className="bg-white px-3 text-base font-semibold text-gray-900">Notes</span>
</div>
</div>

<div className="notes mt-8 p-4 bg-white rounded-lg shadow-md">
{isEditing ? (
<div >
<TextField
    label="Note"
    multiline
    rows={9}
    value={note}
    onChange={handleNoteChange}
    variant="outlined"
    fullWidth
    className="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
/>
<div className="mt-4">
<Button
    variant="outlined"
    size="small"
   className="mt-9 w-32 bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
    onClick={handleSaveClick}
>
    Save
</Button>
</div>
</div>
) : (
<div className="noteModifier">
<p className="text-gray-700 text-sm mb-2" style={{ whiteSpace: 'pre-wrap' }}>
    Note: {note}
</p>
<div className="flex justify-end">
    <Button
        variant="outlined"
        className="mt-4 w-32 bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
        onClick={handleModifyClick}
    >
        Modify
    </Button>
</div>
</div>
)}
</div>
</div>
</CustomTabPanel>
</Box>

</div>
          
          <Dialog open={openDate} onClose={handleCloseDate}>
                <DialogTitle>Select a Date</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={selectedDate}
                            onChange={handleDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDate} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={changeDeadline} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            </div>

       

          

      

     


      
     
    
    

        </div>

    


    
   
           
    
        </div>
    )
    }
}