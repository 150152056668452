import './register.css';
import { useState } from "react";
import Axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" >
       DocUp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const contract = `

<h4> Introduction </h4>
<br>

This User Agreement ("Agreement") is made between DOCUP ("Company") and the user ("User") who creates an account on our application ("Application"). By creating an account and using our Application, the User agrees to the terms and conditions set forth in this Agreement.<br><br>

<h3>1. Collection and Use of Data <br><br></h3>

<h4>1.1 Collected Data: <br><br></h4>

Personal Information: This includes, but is not limited to: name, email address, contact details, and any other information provided by the User when creating the account or using the Application.<br><br>
Client Information: This includes documents and data submitted by the User's clients as part of the services provided through the Application.<br><br>
Usage Data: This includes information about how the User interacts with the Application, including IP addresses, browser types, and access times.<br><br>

<h4>1.2 Use of Data:<br><br></h4>

Here is the framework in which we use your data:<br><br>
- To provide, operate, and maintain our Application.<br><br>
- To improve, personalize, and develop our Application.<br><br>
- To understand and analyze how the User uses our Application.<br><br>
- To develop new products, services, features, and functionalities.<br><br>
- To communicate with the User, including for customer service, updates, and other information related to the Application.<br><br>
- To process transactions and manage subscriptions.<br><br>
- To detect and prevent fraud and other illegal activities.<br><br>

<h3> 2. Limitations on Data Use<br><br> </h3>

<h4> 2.1 Prohibited Uses: <br><br> </h4>

<span style="font-weight: bold; color: #333"> We will never use your data to:<br><br> </span>

- Engage in business activities related to the User's industry, including, but not limited to, immigration services or other services related to the User's profession.<br><br>
- Contact the User's clients for any reason other than a technical support request made by the User.<br><br>
- Sell or rent personal data to third parties.<br><br>
- Share personal data with third parties other than those listed in section 3.1.<br><br>
- Use the data for purposes other than those specified in this Agreement without the User's explicit consent.<br><br>

<h3>3. Data Protection and Security <br><br></h3>

<h4>3.1 Data Security: </h4> <br> We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk of processing personal data. This includes, but is not limited to, encryption, access controls, and regular security audits.<br><br>

<h4>3.2 Data Retention:</h4> <br> We will retain personal data only as long as necessary to achieve the purposes for which it was collected, namely to ensure the proper functioning of the various features of the application. The User can at any time, by deleting their account, proceed to the complete deletion of their data on our servers (information and documents).<br><br>

<h4> 3.3 Location of Data: </h4> <br> All data is stored in North America with a host that complies with industry standards (FedRAMP, EU Data Directive, FISMA ...)<br><br>

<h3> 4. Sharing and Disclosure of Data<br><br> </h3>

<h4> 4.1 Third-Party Service Providers:</h4> <br> We may share data with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery. The providers used are industry leaders and are required to maintain the confidentiality and security of the data and to use it only for the purposes for which it was disclosed.<br><br>

<h4> 4.2 Legal Requirements: </h4> <br> We may disclose data only if required by law or in response to legitimate requests from public authorities.<br><br>

<h3> 5. User Responsibilities <br><br> </h3>

<h4> 5.1 Account Security: </h4> <br> The User is responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.<br><br> 

<h4> 5.2 Compliance with Laws: </h4> <br> The User is responsible for complying with all applicable laws regarding the collection and processing of data.<br><br> </h4>

<h3> 6. Termination <br><br> </h3>

<h4> 6.1 Termination by the User: </h4> <br> The User may terminate this Agreement at any time by closing their account and ceasing to use the Application. In the event of account deletion, all their data will be deleted.<br><br> 

<h4> 6.2 Termination by the Company: </h4> <br> The Company may terminate this Agreement and the User's access to the Application at any time, with or without cause, with or without notice.<br><br> </h4>

<h3> 7. Governing Law<br><br> </h3>

This Agreement is governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.<br><br>

<h3> 8. Warranties<br><br> </h3>

We place great importance on your satisfaction. Although we have made considerable efforts to develop and maintain the infrastructure related to the application, by becoming a User, you agree not to hold DocUp responsible for technical issues, including the loss of your files (Client Data). We provide you with tools to regularly download backups of your files and strongly encourage you to use this feature.
The data is provided by DocUp "as is" and "as available." To the fullest extent permitted by law, DocUp makes no representations or warranties of any kind, express or implied, regarding the use or performance of the infrastructure, including its accuracy, reliability, or other aspects. DocUp will not be liable for any interruption or disruption of the use of the infrastructure. All warranties, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement, are hereby disclaimed.<br><br>

<h3> 9. Network and Information Security<br><br> </h3>

Ensuring the security of your data is a priority for us. DocUp implements comprehensive measures to protect the information we collect from you, in order to prevent loss, misuse, and unauthorized access, disclosure, alteration, and destruction. Sensitive personal information, such as credit card numbers, passwords, and your clients' data, is encrypted during transmission over the Internet using the Secure Socket Layer (SSL) protocol.<br><br>

Our servers are located in a controlled environment with restricted access. While we employ robust measures to protect the personal information collected directly from you, no security system is completely infallible.<br><br>

Access to your account and our services is limited by individual user credentials and passwords. To ensure the confidentiality of your personal information, you must keep your password secure and not share it with anyone. Please inform us immediately if you believe your password has been compromised. Additionally, always log out and close your browser at the end of your session. Please note that we will never ask you to disclose your password in an unsolicited phone call or email.<br><br>

<h3> 10. Amendments<br><br> </h3>
The Company reserves the right to modify this Agreement at any time. The User will be informed of any changes, and continued use of the Application after such notification constitutes acceptance of the modified terms.<br><br>

<h3> 11. Modification Clause<br><br> </h3>

We reserve the right to modify the rates, features, and limitations of our plans at any time. Any changes to rates or plans will be communicated to users through appropriate channels, such as emails or notifications within the application. Continued use of the service after such modifications will constitute your consent to these changes.
<br><br>
<h3> 12. Contact Information<br><br> </h3>

For any questions or concerns regarding this Agreement, please contact us at support@docup-app.com.


`;

export default function Register() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setRegisterConfirmPassword] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setConfirmEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [validated, setValidated] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [error, setError] = useState('');

  const [openWaiting, setOpenWaiting] = React.useState(false);

  const handleCloseWaiting = () => {
    setOpenWaiting(false);
  };
  const handleOpenWaiting = () => {
    setOpenWaiting(true);
  };

const handlePasswordChange = (e) => {
  setRegisterPassword(e.target.value);
};

const showContract = (type) => () => {
  setOpen(true);
  setScroll(type);

};

const handleClose = () => {
  setOpen(false);
};

const descriptionElementRef = React.useRef(null);
React.useEffect(() => {
  if (open) {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }
}, [open]);

const handleConfirmPasswordChange = (e) => {
  setRegisterConfirmPassword(e.target.value);
};

const register = async (e) => {
  e.preventDefault()

  setUserExist(false)
  setEmailExist(false)
  
 
  

  const isValidPassword = /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(registerPassword);


  if (!isValidPassword) {
    setError("Your password need to have at least 6 characters,  an uppercase letter as well as a digit. ");
    return;
  }

  // Check if passwords match
  if (registerPassword !== confirmPassword) {
    setError("Passwords don't match. Please try again");
    return;
  }
  // Check if email match
  if (email !== emailConfirm) {
    setError("Emails don't match. Please try again");
    return;
  }


  if (!acceptTerms) {
    setError("You must accept the terms of use.");
    return;
  }
  // All validations passed, you can proceed with your form submission or other actions here
  setError('');

  try {
    handleOpenWaiting()

    const response = await Axios({
    method: "POST",
    data: {
   
      consultant_Password: registerPassword,
      consultant_name: registerName,
      companyName : companyName,
      email: email,

    },
    withCredentials: true,
    url: `${backEndUrl}/users`,
  }
  )

  
  if (response.status === 200) {
    handleCloseWaiting()
    // Handle success (e.g., show a success message to the user)
    setValidated(true); // Set success to true if registration is successful
    setUserExist(false)
        // Reset input fields
        setEmail(""); // Reset email
        setConfirmEmail(""); // Reset confirm email
        setRegisterPassword(""); // Reset password
        setRegisterConfirmPassword(""); // Reset confirm password
        setRegisterName(""); // Reset full name
        setCompanyName(""); // Reset company name
    
  }else if (response.status === 204) {
    setUserExist(true)
    setValidated(false)
    handleCloseWaiting()
    
    // Handle the case where the user already exists (e.g., show an error message to the user)
  } else if (response.status === 205) {
    setEmailExist(true)
    setValidated(false)
   handleCloseWaiting()
  }


} catch (error) {
  alert("Error during registration:", error);
  handleCloseWaiting()
}
};


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
           // ITALY backgroundImage: 'url(https://images.pexels.com/photos/17850754/pexels-photo-17850754/free-photo-of-buildings-around-narrow-canal-in-town.jpeg)',
            backgroundImage: 'url(https://images.pexels.com/photos/2529179/pexels-photo-2529179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <Box component="form" onSubmit={register} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email "
                value={email}
                name="email"
                autoComplete="email"
             
                onChange={(e) => setEmail(e.target.value)}
              />
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Confirm email "
                value={emailConfirm}
                name="emailConfirm"
                autoComplete="email"
             
                onChange={(e) => setConfirmEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                value={registerPassword}
                label="Password"
                type="password"
                id="password"
     
                helperText='Your password must contain at least 6 characters and include an uppercase letter.'
                 onChange={handlePasswordChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                value={confirmPassword}
                type="password"
                id="confirmPassword"
          
                onChange={handleConfirmPasswordChange}
              />
      
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                value={registerName}
                label="Full name"
                type="text"
                id="name"
               
                onChange={(e) => setRegisterName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="companyName"
                label="Your company Name"
                value={companyName}
                type="text"
                id="Company Name"
                helperText='To enhance the experience of your client, we will display your company name on the client platform.'
                onChange={(e) => setCompanyName(e.target.value)}
              />

<FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    color="primary"
                  />
                }
                label={<span>I have read, understand, and agree to <Link onClick={showContract('paper')} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>the Terms of Use.</Link></span>}
              />
          
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
              <Grid container>
              {validated && <span style={{color:"green", marginTop:"10px", fontSize: "20px"}}>Account created! Please return to the home screen <a style={{ fontWeight: 'bold', textDecoration: 'underline', fontSize: '23px' }} href="https://docup-visa.com">here</a></span>}
        {userExist && <span style={{color:"red", marginTop:"10px", fontSize: "20px"}}>Username already taken, please choose another one.</span>}
        {emailExist && <span style={{color:"red", marginTop:"10px", fontSize: "20px"}}>The email already exists in the database.</span>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
             
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>


        <React.Fragment>
  
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 'none' } }}
      >
        <DialogTitle id="scroll-dialog-title">Terms of Use</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          <div dangerouslySetInnerHTML={{ __html: contract }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button variant="contained" size='small' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>


      </Grid>

      <div>
     
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openWaiting}
     
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </ThemeProvider>

 
    
  );

 
}