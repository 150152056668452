import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Axios from 'axios';


import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';





// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [email, setEmail] = React.useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
        Axios({
      method: "POST",
      data: { email: email },
      withCredentials: true,
      url: `${backEndUrl}/update/resetPasswordRequest`,
    }).then((res) => {
      if (res.data.message && res.data.message.includes('Too many requests')) {
        alert('Too many attempts. Please try again in 5 minutes.');
      } else if (res.data !== 'No User Exists') {
        alert('An email has been sent to your email address. Please check your inbox and spam folder. ');
      } 
      setEmail('')
    }).catch((error) => {
      console.error('Error:', error.AxiosError);
      if (error.response && error.response.status === 429) {
        alert('Too many requests. Please try again in 5 minutes.');
        return;
      }

       if (error.response.data.message === 'User not found'){
        alert('No user with this email has been found.');
        return
      }
      alert('Error : please try again later or contact support.');
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot your password ?
          </h2>
        </div>
        <div>
          <p className="mt-10 text-center text-sm text-gray-800">
          Enter your email address below to receive a password reset email.
          </p>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email adress
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Submit
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account ?{' '}
            <a href="/" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Log in 
            </a>
          </p>
        </div>
      </div>
  );
}