// Desc: This is the page for the docTemplate
import * as React from 'react';
import './DocTemplate.scss'
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import { useEffect } from "react";
import Axios from "axios";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import Input from "../../component/input/Input";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateIcon from '@mui/icons-material/Create';


//Successful alert 
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Backdrop for loading
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


export default function DocTemplate() {
    const [data, setData] = useState();
    const [templateName, setNewTempName] = useState("");
    const [formValues, setFormValues] = useState([]);
    const [toggle, setToggle] = useState(true);
    const [numberOfDocs, setNumberDoc] = useState(0);
    const [newDocs, setNewDocs] = useState("");
    const [noDocError, setNoDocError] = useState(false);
    const [limit, setClientLimit] = useState(false);

    const [fileNameHandleAddField, setForHandleAddField] = useState('');

    const [inputDoc, setInputDoc] = useState("");
    const [desDoc, setDesDoc] = useState("");


    const [fileName, setFileName] = useState([]);

    const [openBackdrop, setOpenBackDop] = React.useState(false);
    const handleCloseBackDrop = () => {
      setOpenBackDop(false);
    };
    const handleOpenBackDrop = () => {
      setOpenBackDop(true);
    };


    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 


   
  const onChangeFile = (e) => {
    setForHandleAddField([e.target.files[0]]);
  setFileName(prevFileNames => [...prevFileNames, e.target.files[0]]);
};

     // Sucessful submit alert 

     const [open, setOpen] = React.useState(false);

     const handleClick = () => {
       setOpen(true);
     };
   
     const handleClose = (event, reason) => {
       if (reason === 'clickaway') {
         return;
       }
   
       setOpen(false);
     };

  
     // end sucessful submit alert 
  
     const newTemplate = async (e) => {

     
      if (newDocs.length === 0) {
        setNoDocError(true); 
        e.preventDefault();
      return
      }

      e.preventDefault();
    
      handleOpenBackDrop();

      const formData = new FormData();
      formData.append('name', templateName);
      formData.append('docNumber', numberOfDocs);

      

      newDocs.forEach((doc, index) => {
  // add option to add optional document 
  // add attached document

        formData.append(`documents[${index}][name]`, doc.docName);
        formData.append(`documents[${index}][description]`, doc.docDescription);
        formData.append(`documents[${index}][doc_upload]`,  doc.doc_upload_consultantSide ||  'none' );



      
      });

      
      fileName.forEach((file) => {
        if (file) {
          
            formData.append('doc_upload', file);
        }
    });

 for (let [key, value] of formData.entries()) {
      
    }

      formData.forEach((value, key) => {
         // This will help you see what is being appended
      });
      
      try {
        await Axios({
          method: "PUT",
          url: `${backEndUrl}/update/newTemp/` + data.id,
          data: formData,
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        });
    
        successfulSubmit();
    
    /*     setTimeout(() => {
          window.location.reload();
        }, 2000); */
      } catch (error) {
                alert('There was an error creating the template. Please try again.');
      } finally {
        handleCloseBackDrop();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
    


    const successfulSubmit = () => {
      setOpen(true)
    }

    const handleChange = (e, index) => {
        const values = [...formValues];
        values[index].value = e.target.value;
        setFormValues(values);
      };    
    
      const handleAddField = (e) => {
         e.preventDefault();
        const values = [...formValues];

        if (inputDoc == '' || desDoc == '') {
          alert('Please write first a name and description for your document.')
          return
        }
      
        values.push({
          docName: inputDoc|| "",
          docDescription: desDoc || "",
          doc_upload:fileName,
          doc_upload_consultantSide: fileNameHandleAddField,
          // value: "",
        });
        setFormValues(values);
        setInputDoc("");
        setDesDoc("");
       // setToggle(false);
        setNumberDoc(numberOfDocs + 1);
        setNewDocs(values)
    
      };
    
      const handleDeleteField = (e, index) => {
        const values = [...formValues];
        values.splice(index, 1);
        setFormValues(values);
        setNumberDoc(numberOfDocs -1);
        setNewDocs(values)
      };
    
      const addBtnClick = (e) => {
        setForHandleAddField('');
        e.preventDefault();
        setToggle(true);
       
      };

if (!data) {
  return (
    <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
  )
}


    return (
        <div className="hdsfds">
     
   

    <div className="-mx-4 px-1 py-2 shadow-md ring-gray-900 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-2 xl:pb-20 xl:pt-10">
    
        <div>
        <h2 className="text-base/7 font-semibold text-gray-900 mt-5 ml-10">Create a new template</h2>
              <p className="mt-4 text-sm text-gray-500 ml-10 pr-4 max-w-5xl leading-relaxed">
              If you frequently request the same documents, you can use this section to create a document template that you can easily select when creating a new client.
              You can also attach reference documents for your clients (e.g., forms to fill out, bank certificate templates, etc.) that will be visible to them directly in their client portal.
              </p>


   
<form onSubmit={newTemplate}>

<div className='templateForm'>

<div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
  
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>


<div style={{marginTop: '4%'}}>


        <label className="text-m font-medium text-gray-900 ml-7">Template name : </label>
<TextField  required style={{marginLeft: '7%'}} className="w-41 " id="outlined-basic"  size="small" type="text" placeholder="Enter Name" onChange={(e) => setNewTempName(e.target.value)}/> 
 </div>

 <div className="mx-auto max-w-full mt-4 sm:px-6 lg:px-0 shadow-sm ring-1 ring-gray-900/5 rounded-lg p-4">

    
{!toggle ? (
  

  <div className="addNewBtnTemplate">
  <div style={{marginTop: '4%'}}>
 <label  >Client document : </label> 


    <Button style={{marginLeft: '4%'}} variant="contained"  onClick={addBtnClick}>
     Add document 
    </Button>
    </div>
  </div>
) : (
  <div className="">
    {/* Document Name Row */}
    <div className="flex items-center mb-4">
        <label htmlFor="documentName" className="text-m font-medium text-gray-900 mr-4 ml-8">Document Name:</label>
        <div className='ml-14'>
        <TextField
            
            id="documentName"
            size="small"
            type="text"
            placeholder="Enter document name"
            value={inputDoc}
            className="inputDoc  " // Adjust width as needed
            onChange={(e) => setInputDoc(e.target.value)}
        />
    </div>
    </div>

    
    <div className="flex items-center mb-4">
        <label htmlFor="documentRequirements" className="text-m font-medium text-gray-900 mr-4 ml-8 ">Document Requirements:</label>
        <TextField
            id="documentRequirements"
            
            size="small"
            type="text"
            placeholder="Enter document requirements"
            value={desDoc}
            multiline
            rows={4}
            className="desDoc w-1/2" // Adjust width as needed
            onChange={(e) => setDesDoc(e.target.value)}
        />
    </div>

    {/* File Upload Row */}
    <div className="flex items-center mb-4">
    <div>
        <label htmlFor="file" className="text-m font-medium text-gray-900 block mb-1 ml-8 ">Upload a reference document (optional):</label>
     
        </div>
        <input
            id="file"
            type="file"
            className="ml-4 p-1"
            onChange={onChangeFile}
        />
    </div>
    <p className="text-xs text-gray-500 mb-2  mt-2 ml-8 ">
            You can upload a document that will be used as a reference for the client (example: bank certificate template, form to fill out, etc.)
        </p>
    {/* Add Document Button */}
    <Button
        startIcon={<AddCircleIcon />}
        style={{ marginTop: '4%', marginLeft: '50%', width: '20%' }}
        variant="contained"
        
        onClick={handleAddField}
    >
        Add Document 
    </Button>
  </div>
 
)}


</div>
<h2 className="text-base/7  font-semibold text-gray-900 mt-8 ml-10">Document checklist</h2>
{newDocs.length === 0 && (
        <p className="text-red-500 mt-2 ml-10">No document added yet</p> 
      )}

<div className='mt-5'>
{formValues.map((obj, index) => (
      <Input
        key={index}
        objValue={obj}
        onChange={handleChange}
        index={index}
        deleteField={handleDeleteField}
      />
    ))}
    </div>

 <Button startIcon={<CreateIcon />} style={{marginTop: '8%', marginBottom:'15%', marginLeft:'40%'}}  variant="contained" type="submit" >
      Create
    </Button>


</div>

</form>



        </div>
        {limit ? <p style={{color: 'red', marginTop: '2%'}}>You have reached the limit of templates for your subscription</p> : null}
        </div>
       
  
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Template created successfully !
        </Alert>
      </Snackbar>


      <Dialog open={noDocError} onClose={setNoDocError} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-red-100">
                <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  No document added
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Please add document first to your template.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={() => setNoDocError(false)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
   
  
</div>
        
    )
}